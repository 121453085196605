import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '67.5%',
            marginBottom: '330px',
            marginLeft: '27.99%',
            marginTop: '237px',
            [theme.breakpoints.down(1000)]: {
                width: '70%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(701)]: {
                width: '71%',
                marginLeft: '21%',
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '200px',
                marginTop: '150px',
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '85px',
                marginTop: '125px',
            },
        },
        titleContainer: {
            width: '82%',
            marginBottom: '145px',
            marginTop: '15px',
            '& p': {
                fontSize: '109px',
                lineHeight: '131px',
                color: '#3D4975',
                letterSpacing: '7px',
                fontWeight: 900,
                marginBottom: 0,
            },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '70px',
                '& p': {
                    fontSize: '69px',
                    lineHeight: '75px',
                    letterSpacing: '6.34px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '60px',
                marginTop: '15px',
                '& p': {
                    fontSize: '57px',
                    lineHeight: '66px',
                    letterSpacing: '6px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& p': {
                    fontSize: '50px',
                    lineHeight: '54px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& p': {
                    fontSize: '37px',
                    lineHeight: '41px',
                    letterSpacing: '3.43px',
                },
            },
            [theme.breakpoints.down(400)]: {
                '& p': {
                    fontSize: '30px',
                    lineHeight: '30px',
                },
            },
        },
        titleTop: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
        },
        titleTopTop: {
            gridColumnStart: 1,
            gridRowStart: 1,
            placeSelf: 'start start',
        },
        titleTopBot: {
            gridColumnStart: 1,
            gridRowStart: 2,
            placeSelf: 'start end',
        },
        titleBot: {
            width: '98%',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
        },
        titleBotTop: {
            gridColumnStart: 1,
            gridRowStart: 1,
            placeSelf: 'start center',
        },
        titleBotBot: {
            gridColumnStart: 1,
            gridRowStart: 2,
            placeSelf: 'start end',
        },
        subtitleContainer: {
            alignSelf: 'start',
            marginBottom: '130px',
        },
        miniSub: {
            fontSize: '32.07px',
            color: '#3D4274',
            letterSpacing: '2px',
            lineHeight: '50px',
            fontWeight: 500,
            width: '100%',
            marginBottom: 0,
            [theme.breakpoints.down(1000)]: {
                fontSize: '34px',
                letterSpacing: '1.2px',
                lineHeight: '52px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '28.13px',
                letterSpacing: '2px',
                lineHeight: '42px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '25px',
                letterSpacing: '1.8px',
                lineHeight: '40px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '16.07px',
                letterSpacing: '1.14px',
                lineHeight: '25px',
                marginBottom: '40px',
            },
        },
        subtitle: {
            fontSize: '59.5px',
            color: '#3D4975',
            letterSpacing: '7px',
            fontWeight: 900,
            marginBottom: 0,
            [theme.breakpoints.down(1000)]: {
                fontSize: '55.24px',
                letterSpacing: '6.34px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '52.24px',
                letterSpacing: '6px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '45px',
                letterSpacing: '5.5px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '29.85px',
                letterSpacing: '3.43px',
            },
        },
        infoContainer: {
            width: '60%',
            fontWeight: 400,
            fontSize: '18.4px',
            lineHeight: '22.08px',
            letterSpacing: '0.55px',
            color: '#3D4975',
            [theme.breakpoints.down(750)]: {
                width: '75%',
            },
            [theme.breakpoints.down(600)]: {
                width: '85%',
                fontSize: '16px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(600)]: {
                width: '95%',
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
        infoBox: {
            paddingBottom: '32px',
            marginBottom: '32px',
            borderBottom: '1px solid #A2A5BD',
        },
        infoTitle: {
            fontWeight: 800,
            marginBottom: '14px',
        },
        noMB: { marginBottom: 0 },
        contentLink: {
            textDecoration: 'none',
            color: '#3D4975',
        },
        contentEmail: { color: '#3D4975' },
    });
