// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// local imports
import { styles } from './Aclaraciones.style';

function Aclaraciones({ classes }: WithStyles<typeof styles>) {
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Box className={classes.titleContainer}>
                    <Box className={classes.titleTop}>
                        <p className={classes.titleTopTop}>CONTRO</p>
                        <p className={classes.titleTopBot}>VERSIAS</p>
                    </Box>

                    <Box className={classes.titleBot}>
                        <p className={classes.titleBotTop}>Y ACLARA</p>
                        <p className={classes.titleBotBot}>CIONES</p>
                    </Box>
                </Box>

                <Box className={classes.subtitleContainer}>
                    <p className={classes.miniSub}>Servicio</p>
                    <p className={classes.subtitle}>CON</p>
                    <p className={classes.subtitle}>EMPATÍA</p>
                </Box>

                <Box className={classes.infoContainer}>
                    <Box className={classes.infoBox}>
                        <p className={classes.infoTitle}>NÚMERO DE WHATSAPP</p>
                        <a target="_blank" href="https://wa.me/525513285364" className={classes.contentLink}>
                            55.13.285.364
                        </a>
                    </Box>

                    <Box className={classes.infoBox}>
                        <p className={classes.infoTitle}>HORARIO</p>
                        <p>Lunes a Viernes de 10:00 hrs a 22:00 hrs</p>
                        <p className={classes.noMB}>Sábados y domingos de 15:00 hrs a 22:00 hrs.</p>
                    </Box>

                    <Box className={classes.infoBox}>
                        <p className={classes.infoTitle}>CORREO ELECTRÓNICO</p>
                        <a target="_blank" href="mailto:une@cuenca.com" className={classes.contentEmail}>
                            une@cuenca.com
                        </a>
                    </Box>

                    <Box className={classes.infoBox}>
                        <p className={classes.infoTitle}>NÚMERO TELEFÓNICO</p>
                        <a target="_blank" href="tel:5575118947" className={classes.contentLink}>
                            55.75.118.947
                        </a>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(Aclaraciones);
