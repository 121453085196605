// React imports
import React from 'react';

// gatbsy imports
import { PageProps, graphql } from 'gatsby';

// SEO
import SEO from 'components/seo';

// Theme and layout
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';
import Aclaraciones from 'views/Aclaraciones';

type DataProps = {
    site: {
        buildTime: string;
    };
};

const AclaracionesPage: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <WithTheme>
        <Layout location={location}>
            <SEO title="Controversias y Aclaraciones" />

            <Aclaraciones />
        </Layout>
    </WithTheme>
);

export default AclaracionesPage;

export const query = graphql`
    {
        site {
            buildTime(formatString: "YYYY-MM-DD hh:mm a z")
        }
    }
`;
